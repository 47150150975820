.presentaion-center {
    margin: auto;
    width: 70%;
    padding: 40px;
    text-align: center;
}

.ant-carousel .slick-dots li button {
    background: #532c2c;
}

.ant-carousel .slick-dots li.slick-active button {
    background-color: #225e96;
}

.control-column {
    /* No height property here because it is set via inline styles */
    display: flex;
    align-items: center; /* Centers vertically in the column */
    justify-content: center; /* Centers horizontally in the column */
    cursor: pointer;
}

.control-column:hover {
    background-color: rgba(0, 0, 0, 0.01); /* Light background on hover */
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05); /* Inner shadow for a subtle depth effect */
}

.control-column .anticon {
    font-size: 24px; /* Adjust the size of the arrow icons */
    transition: transform 0.3s ease; /* Smooth transition for transformations */
}

.control-column:hover .anticon {
    transform: scale(1.5); /* Slightly enlarge icons on hover */
}

.carousel-style {
    padding: 0px 0px 50px 0px;
    margin-top: 10px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #ffffff;
    color: #000000;
}

.slogan-description {
    white-space: pre-line;
}

.slogan-pic {
    height: 750px;
    width: 100%;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 8px;
}

@media (max-width: 1024px) {
    .slogan-pic{
        height: 500px;
    }
}

@media (max-width: 768px) {
    .slogan-pic{
        height: 350px;
    }
}

@media (max-width: 425px) {
    .slogan-pic{
        height: 200px;
    }
    .slogan-description {
        font-size: 12px;
    }
}

.subscriptionCard {
    width: 250px;
    border-radius: 25px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    font-family: sans-serif;
    font-size: 16px;
    color: #555555;
    margin: 1em;
    height: 860px;
}

.subscriptionCardLabel {
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.subscriptionCard h2 {
    color: white;
    font-size: 1.5em;
    font-weight: 400 ;

}

.subscriptionCardDescription {
    padding: 15px;
    height: 30%;
    display: flex;
    /* align-items: center; */
}

.subscriptionCardPrice {
    padding: 15px;
    display: flex;
}

.subscriptionCardList {
    padding: 0;
    margin: 0;
    text-align: left;
}

.subscriptionCardFeature {
    list-style: none;
    margin: 0;
    padding: 5px 0 0 25px;
    position: relative;
    font-size: 1rem;
    margin-bottom: 0.5em;
}

.subscriptionCardFeature::before {
    content: "\2714";
    color: #1890ff;
    color:var(--hover-colorFeature);
    position: absolute;
    left: 0;
}

.subscriptionCardSubscribe {
    padding: 15px;
    border-top: 1px solid #eeeeee;
    text-align: center;
}

.borderSeparator {
    border-top: 1px solid #eeeeee;
}

.subscriptionCard a {
    display: inline-block;
    width: 150px;
    margin: 8px auto;
    padding: 8px 8px;
    text-decoration: none;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-weight: bold;
}

.subscriptionCard a:hover {
    background:var(--hover-background);
    color: #ffffff !important;
}

@media (max-width: 1023px) {
    .subscriptionCard {
        width: 280px;
    }
}

.subscriptionContainer {
    display: -webkit-inline-flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

h1 {
    color: #1890ff;
    margin: 1.5em;
    font-weight: 450;
}

@media (max-width: 1023px) {
    .subscriptionContainer {
        flex-wrap: wrap;
    }

    .line-break {
        width: 100%;
    }
}



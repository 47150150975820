body {
  background-color: #2697ff!important;
}
.app-content {
  margin-top: 64px;
  margin-bottom: 64px;
  height: 100%;
  text-align: center;
  background-color: #ffffff;
}

.footer-style {
  position: relative;
  text-align: center;
  bottom: 0;
  width: 100%;
  background-color: #2697ff!important;
  box-shadow: 0 -8px 16px #878585ad;
}

.content-style {
  margin-top: 64px;
  height: 160px;
  color: #fff;
  text-align: center;
  background-color: #ffffff;
}

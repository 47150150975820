
.app-footer {
    margin-top: 0;
    height: 100%;
}

.socials {
    color: #d7d7d7;
    font-size: 20px;
    list-style-type: none;
    transition-property: font-size;
    transition-delay: 30ms;
    transition-duration: 50ms;
    transition-timing-function: ease-in;
  }
.socials-icon {
    margin-top: 200px;
    font-size: 20px;
    color: #d7d7d7;
}
.socials-icon:hover {
    color: #ffffff;
}
li {
    list-style-type: none;
}
.socials:hover {
   font-size: 22px;
    color: white;
}
.footer-links{
    text-align: left;
}
.footer-content{
    justify-content: center;
}
.footer-title {
    text-align: left;
    color: white;
    margin: 0 0 20px 0;
}
.footer-links a{
    text-align: left;
    color: #d7d7d7;
    transition-property: font-size;
    transition-delay: 30ms;
    transition-duration: 50ms;
    transition-timing-function: ease-in;
}
.footer-links a:hover{
    text-align: left;
    color: #ffffff;
    font-size: 16px;
}
.socials a {
    color: #d7d7d7;
}

.socials-container {
 text-align: right;
}

@media (max-width: 575px) {
    .footer-title {
        text-align: center;
    }
    .socials-container {
        text-align: center;
    }
    .footer-links{
        text-align: center;
    }
}

@media (max-width: 419px) {
    .footer-title {
        font-size: 20px;
    }
}